import { Region } from "@/lib/regions"



export const SvgOverlay = ({ onClick, path }: { onClick: (e: any) => void, path: string }) => {
  return (
    <path
      onClick={(e) => {
        e.stopPropagation();
        onClick(e)
      }}
      z={10000}
      style={{ opacity: 0, fill: 'red', zIndex: 10000, cursor: 'pointer' }}
      d={path} />
  )
}

const PATH_MAP: { [x: string]: string } = {
  "NA": "M 4.6062264,-0.18395786 5.205398,11.286097 7.8107994,37.320588 29.18686,35.890951 l 9.922751,-0.246346 4.386272,3.041817 8.337512,1.50221 4.989839,-5.935982 11.88756,-8.464619 -4.944655,-20.172603 -4.360992,-7.5837644 z",
  "LAN": "m 30.71449,36.206625 10.581754,16.712598 15.869334,11.56974 0.084,-5.298188 c 0,0 -1.400416,0.913412 -1.839187,0.658308 -0.438771,-0.255104 0.682112,-5.367926 0.682112,-5.367926 l 1.892048,-0.80305 1.645777,-0.404758 2.917969,-1.800249 1.099087,1.459547 2.566533,-0.165016 1.285072,-2.48764 -3.706686,-12.669294 -10.182685,0.836362 -1.948747,2.24111 -7.844895,-0.339888 -4.998527,-2.666394 -2.99606,-1.318258 z",
  "JP": "m 166.10969,32.045282 -3.77101,3.941099 c 0,0 1.25732,7.231668 1.78406,7.231668 0.52673,0 8.88185,-10.650197 8.88185,-10.650197 l -1.14859,-5.845913 -3.56114,0.08829 z",
  "BR": "m 67.046984,73.341885 4.206299,-2.22093 8.87756,-5.937997 -3.055412,-11.572632 -6.869663,-2.199993 -2.718699,-1.083182 -0.46822,2.375395 0.167978,1.157404 -4.346066,0.364089 0.146333,-1.330659 -1.690707,-0.09507 -1.518109,1.617611 -2.216536,0.818772 0.130169,2.008168 -1.247213,2.188931 4.212385,0.438645 1.997744,1.53974 1.39289,2.919649 1.042085,2.757757 1.208774,1.455727 -1.523943,2.273538 z",
  "LAS": "M 64.193317,89.33755 69.884841,74.693418 66.473195,72.700094 64.998022,71.074307 65.62053,68.400955 65.355368,66.436688 63.708353,64.097761 62.107506,61.22353 59.543019,59.624091 h -1.837217 l -0.395937,5.01445 -5.592571,2.90452 -1.970038,14.268185 -1.611808,8.560698 z",
  "TR": "m 112.3472,29.17619 -4.99698,1.124968 1.7961,4.506175 4.06378,0.396386 1.6191,-2.498348 5.50607,0.27546 v -2.673612 z",
  "EUW": "m 86.407799,13.996203 -5.295587,19.268328 7.923163,1.714233 6.536209,-2.234973 5.832476,0.864694 3.64355,-0.743345 -2.54075,-4.123498 -2.23495,-1.29143 2.74752,-2.594472 -2.77194,-1.859448 1.83613,-2.220281 -1.48606,-1.837476 -3.782829,0.503831 -2.265179,-9.507158 z",
  "RU": "m 110.74282,2.7822055 1.22385,8.7453955 -2.32796,2.395107 1.9762,4.499546 1.92983,3.139621 3.18224,2.838633 -2.58297,3.176672 0.84633,1.481052 1.50113,-2.037276 2.98879,1.673098 1.24067,-2.677627 -1.49533,-3.354461 6.38761,-0.301164 2.50637,-4.133158 8.12359,1.625345 4.12187,3.476801 6.35669,-0.816133 9.53729,0.934425 2.86605,-3.203133 4.57304,3.51875 0.82306,4.187761 19.18132,-7.04103 5.41227,-9.038686 -0.16095,-11.9681585 -74.69299,-0.49572017 z",
  "CHN": "m 158.7479,21.871077 c 0,0 -1.78019,4.746422 -2.45205,5.381558 -0.67186,0.635136 -7.18365,3.610745 -7.18365,3.610745 l -4.60216,-2.115266 -2.31149,-3.259195 -2.55952,-0.268073 -5.37827,5.861048 1.74268,5.276084 3.85461,2.759494 3.77685,0.930264 1.10767,-1.772726 2.85044,3.020571 4.09964,0.97354 5.38358,-1.05369 2.68112,-6.802918 -3.27087,-2.617334 4.26275,-3.010235 3.17206,-2.740066 z",
  "EUNE": "m 63.403691,-1.3297119 0.229349,1.18216046 4.321339,18.00592844 10.100199,-6.099748 9.081551,-1.9879823 6.785106,0.3030393 4.937231,9.254138 2.123624,0.563226 0.99925,1.163118 -1.14486,2.667379 c 0,0 0.72162,1.150231 1.15902,1.243072 0.43741,0.09284 -0.55057,2.953844 -0.55057,2.953844 l 2.84654,1.476961 1.15718,4.839603 2.22701,1.63255 3.71033,-0.388259 -3.31149,-2.227466 -1.00023,-2.674325 0.5917,-1.485364 2.41295,0.238062 3.30314,-1.627675 -0.0811,-2.132913 2.78479,-0.928225 -3.16876,-2.656873 -2.7164,0.483121 -0.25034,-4.466694 -1.40577,-2.836632 1.69629,-11.5001379 0.50373,-3.49674615 z",
  "OCE": "m 168.8335,53.865765 -0.4288,5.831637 -4.34667,-1.716001 -9.42079,5.894014 -7.83489,1.196423 2.70569,17.623289 25.16417,4.449147 15.79819,-0.805588 -0.1478,-24.92938 -9.60053,-3.972227 -5.62935,-5.619589 z",
  "KR": "m 162.72348,27.400603 -3.84642,3.968915 0.97678,5.026072 1.61031,0.612917 3.23237,-5.194046 -0.11772,-3.101822 z"
}


export const NAOverlay = ({ onClick }: { onClick: (e: any) => void }) => {
  return (
    <SvgOverlay onClick={onClick} path={PATH_MAP.NA} />
  )
}


export const MapClickOverlay = ({ onClick, region }: { onClick: (e: any) => void, region: string | Region }) => {
  return (
    <SvgOverlay onClick={onClick} path={PATH_MAP[region as string]} />
  )

}



export const LANOverlay = ({ onClick }: { onClick: () => void }) => {
  return (
    <path
      style={{ opacity: 1, fill: 'red' }}
      d="m 127.22289,81.016682 -2.34791,1.314147 12.14559,11.618213 8.73906,2.426564 -1.24608,12.224984 6.0962,0.64469 1.80578,-1.88586 -0.63282,-2.97934 -1.74735,-1.06027 1.32318,-1.6888 1.27058,-1.15987 -2.0593,-2.189355 4.07919,0.801054 2.09796,-1.878163 1.53493,1.826704 3.86698,-1.196057 -2.49756,-3.119688 -3.22255,-5.222079 -5.16875,-5.740647 -3.03665,0.194956 -2.351,1.87812 -6.22972,-1.844599 -1.51837,1.01431 -4.32222,-3.050518 z"
      id="path12868" />)
}
